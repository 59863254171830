









import { Component, Vue } from 'vue-property-decorator';
import HeaderLogo from '../contents/header/HeaderLogo.vue';

@Component({
  components: {
    HeaderLogo,
  },
})
export default class SimpleHeader extends Vue {}

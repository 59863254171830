







import { Component, Vue } from 'vue-property-decorator';
import FooterBottom from '../contents/footer/FooterBottom.vue';
@Component({
  components: {
    FooterBottom,
  },
})
export default class SimpleHeader extends Vue {
  private pageTop(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}












import { Component, Vue } from 'vue-property-decorator';
import SimpleHeader from '../components/layouts/SimpleHeader.vue';
import SimpleFooter from '../components/layouts/SimpleFooter.vue';

@Component({
  components: {
    SimpleHeader,
    SimpleFooter,
  },
})
export default class SimpleLayouts extends Vue {
  get error() {
    return this.$store.getters['window/isNotFound'];
  }
}
